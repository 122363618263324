body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background-color: #f8f9fa;
  font-size: 14px;
}

@media (max-width: 768px) {
  .border-end-lg {
    border: none !important;
  }
}

a:visited,
a:active,
a {
  text-decoration: none;
  transition: color 0.5s ease;
  color: #000;
}

a:hover {
  color: #000;
}

.cursor-pointer {
  cursor: pointer;
}
